import { Toolbar, Button, Box, Link } from '@mui/material';
import { LangSwitcher } from './LangSwitcher';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

export const Header2 = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const isOnUploadPage = location.pathname.includes('upload');
  const base = process.env.PUBLIC_URL || '';
  const home = base + '/#/';
  const upload = base + '/#/upload';
  return (
      <Toolbar disableGutters sx={{ px: 0 }}>
        <Button
            component={Link}
            href={isOnUploadPage ? home : upload}
            variant="contained"
            color="primary"
          >
            {isOnUploadPage ? t('header.buttonHome') : t('header.buttonUpload')}
          </Button>
        <Box
              sx={{
                marginLeft: 'auto',
              }}
        >
            <LangSwitcher />
        </Box>
      </Toolbar>
  );
};

