import { Button, ButtonGroup } from '@mui/material';
import i18n from '../i18n';

export const LangSwitcher = () => {
  return (
    <ButtonGroup size="small" variant="text" aria-label="text button group">
      <Button size="small" onClick={() => i18n.changeLanguage('en')}>EN</Button>
      <Button size="small" onClick={() => i18n.changeLanguage('fr')}>FR</Button>
      <Button size="small" onClick={() => i18n.changeLanguage('de')}>DE</Button>
    </ButtonGroup>
    )
}

